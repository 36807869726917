<template>
  <!--begin::Quotation Internal Listing-->
  <v-container fluid>
    <v-container>
      <v-row>
        <v-col md="2" v-if="false">
          <v-select
            :disabled="dataLoading"
            :loading="dataLoading"
            :items="statusList"
            v-model="filter.status"
            hide-details
            flat
            solo
            dense
            placeholder="Status"
            color="cyan"
            item-color="cyan"
            class="pt-0 mt-0"
            item-text="text"
            item-value="value"
            v-on:change="getAllQuotations"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <template v-if="item.value == 'submit_for_approval'">
                  <template v-if="userDetails.role == 4">
                    <v-list-item-title
                      v-text="'Submied For Approval'"
                    ></v-list-item-title>
                  </template>
                  <template v-else>
                    <v-list-item-title
                      v-text="'Pending For Approval'"
                    ></v-list-item-title>
                  </template>
                </template>
                <template v-else>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </template>
                <template v-if="item.value == 'submit_for_approval'">
                  <template v-if="userDetails.role == 4">
                    <v-list-item-subtitle
                      v-text="'Submied For Approval'"
                    ></v-list-item-subtitle>
                  </template>
                  <template v-else>
                    <v-list-item-subtitle
                      v-text="'Pending For Approval'"
                    ></v-list-item-subtitle>
                  </template>
                </template>
                <template v-else>
                  <v-list-item-subtitle
                    v-text="item.description"
                  ></v-list-item-subtitle>
                </template>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip :color="item.color" :text-color="item.textcolor">
                  <template v-if="item.value == 'all'">{{
                    item.all_quotations
                  }}</template>
                  <template v-else>{{ item.quotation_status_count }}</template>
                </v-chip>
              </v-list-item-action>
            </template>
          </v-select>
        </v-col>
        <v-col md="2">
          <v-text-field
            v-model.trim="filter.search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            placeholder="Search"
            hide-details
            solo
            color="cyan"
            @keydown.enter="getAllQuotations"
            @keydown.tab="getAllQuotations"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <DateRangePicker
            placeholder="Data Range"
            v-model="dates"
            v-on:update:model-value="getAllQuotations()"
            v-on:click:clear="clearFilter('dates')"
          ></DateRangePicker>
        </v-col>
        <v-col md="2" v-if="false">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                v-on:click="getAllQuotations"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
          <template v-if="type == 'project' && detail.status < 4">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  tile
                  v-on:click="customerPersonDialog = true"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Create New Quotation</span>
            </v-tooltip>
          </template>
        </v-col>
      </v-row>
    </v-container>
    <ListingTable :columnCount="7" :rowData="dataRows2">
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th" width="100">Quotation #</th>
            <th class="simple-table-th" width="300">Title</th>
            <th class="simple-table-th" width="300">Basic Details</th>
            <th class="simple-table-th" width="200">Company</th>
            <th class="simple-table-th" width="100">Amount</th>
            <th class="simple-table-th" width="100">Status</th>
            <th class="simple-table-th" width="100">Dates</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="dataRows2.length > 0">
            <tr
              link
              :key="index"
              v-for="(row, index) in dataRows2"
              v-on:click="
                $router.push(
                  getDefaultRoute('quotation.detail', {
                    params: { id: row.id },
                  })
                )
              "
              class="alternate-listing-row"
            >
              <td class="simple-table-td">
                <Barcode
                  route="quotation.detail"
                  :barcode="row.barcode"
                  :id="row.id"
                ></Barcode>
              </td>
              <td class="simple-table-td">
                <div class="quotation-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis d-flex">
                    <read-more
                      class="custom-read-more"
                      more-str="read more"
                      :text="row?.job_title || '<em>No title</em>'"
                      link="#"
                      less-str="read less"
                      :max-chars="35"
                    >
                    </read-more>
                  </p>

                  <p
                    class="m-0 custom-nowrap-ellipsis-two-line"
                    v-if="row.property && false"
                  >
                    <b>Site Location: </b>
                    <template v-if="row.property.unit_no"
                      >{{ row.property.unit_no }},
                    </template>
                    <template v-if="row.property.street_1"
                      >{{ row.property.street_1 }},
                    </template>
                    <template v-if="row.property.street_2"
                      >{{ row.property.street_2 }},
                    </template>
                    <template v-if="row.property.zip_code"
                      >{{ row.property.zip_code }}
                    </template>
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div class="quotation-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Reference: </b>
                    <template v-if="row.reference">{{
                      row.reference
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Reference</em></template
                    >
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Sales representative: </b>
                    <template v-if="row.sales">{{ row.sales }}</template>
                    <template v-else
                      ><em class="text--secondary"
                        >No Sales representative</em
                      ></template
                    >
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Prepared by: </b>
                    <template v-if="row.prepared_by">{{
                      row.prepared_by
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Prepared by</em></template
                    >
                  </p>
                </div>
              </td>

              <td class="simple-table-td" width="100">
                <div class="quotation-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Display Name: </b>
                    {{ row.display_name }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis" v-if="row.customer">
                    <b>Company: </b> {{ row.customer.company_name }}
                  </p>
                  <p
                    class="m-0 custom-nowrap-ellipsis"
                    v-if="row.contact_person"
                  >
                    <b>Email: </b> {{ row.contact_person.primary_email }}
                  </p>
                  <p
                    class="m-0 custom-nowrap-ellipsis"
                    v-if="row.contact_person"
                  >
                    <b>Phone: </b> {{ row.contact_person.primary_phone }}
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div class="quotation-listing-amount">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Sub Total: </b>
                    {{ $accountingJS.formatMoney(row.sub_total) }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Tax: </b>
                    {{ $accountingJS.formatMoney(row.tax_amount) }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Total: </b>
                    {{ $accountingJS.formatMoney(row.total) }}
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <CustomStatus
                  small
                  :status="row.status"
                  endpoint="quotation/status"
                ></CustomStatus>
              </td>
              <td class="simple-table-td" width="100">
                <div class="quotation-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis" v-if="row.date">
                    <b>Created: </b> {{ formatDate(row.date) }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis" v-if="row.open_till">
                    <b>Validity: </b> {{ getValidityDate(row) }}({{
                      row.validity_till
                    }}Days)
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis" v-if="row.sent_date">
                    <b>Sent: </b> {{ formatDate(row.sent_date) }}
                  </p>
                </div>
              </td>
            </tr>
          </template>
          <tr v-else-if="!dataLoading">
            <td colspan="7">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no quotation at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
    <template v-if="customerPersonDialog">
      <CustomerPersonDialog
        :customerPersonDialog="customerPersonDialog"
        :customer="customer"
        v-on:closeDialog="closeDialog"
        v-on:resetAll="resetAll"
        v-on:selectCustomerPerson="selectCustomerPerson"
      ></CustomerPersonDialog>
    </template>
  </v-container>
  <!--end::Quotation Internal Listing-->
</template>

<script>
import moment from "moment-timezone";
import { QUERY } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import JwtService from "@/core/services/jwt.service";
import DateRangePicker from "@/view/components/DateRangePicker";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  name: "quotation-internal-list",
  data() {
    return {
      parent: 0,
      customer: 0,
      userDetails: {},
      customerPerson: 0,
      customerBilling: 0,
      dates: [],
      statusList: [],
      dataRows2: [
        {
          barcode: "QT-00012",
          job_title: "title",
          sales_user: "",
          customer: {
            display_name: "Abc pvt lte",
            company_name: "Abc pvt lte",
          },
          contact_person: {
            primary_email: "john@gmail.com",
            primary_phone: "+65 1234 5678",
          },
          unit_no: "",
          street_1: "",
          street_2: "",
          zip_code: "",
          status: "Draft",
          date: "09/08/2024",
          open_till: 10,
          sent_date: "19/08/2024",
          sub_total: 1000.0,
          tax_amount: 100.0,
          total: 1100.0,
        },
        {
          barcode: "QT-00012",
          job_title: "title",
          sales_user: "",
          customer: {
            display_name: "Abc pvt lte",
            company_name: "Abc pvt lte",
          },
          contact_person: {
            primary_email: "john@gmail.com",
            primary_phone: "+65 1234 5678",
          },
          unit_no: "",
          street_1: "",
          street_2: "",
          zip_code: "",
          status: "Draft",
          date: "09/08/2024",
          open_till: 10,
          sent_date: "19/08/2024",
          sub_total: 1000.0,
          tax_amount: 100.0,
          total: 1100.0,
        },
      ],
      dataLoading: true,
      customerPersonDialog: false,
      momentObject: moment,
      customerList: [],
      filter: {
        status: "all",
        search: null,
      },
      timeout: null,
      timeoutLimit: 500,
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: true,
      default: "product",
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.parent = this.lodash.toSafeInteger(param.id);
        if (this.parent > 0) {
          if (this.lodash.isEmpty(param.customer) === false) {
            this.customer = this.lodash.toSafeInteger(param.customer.customer);
            if (this.lodash.isEmpty(param.customer_billing) === false) {
              this.customerBilling = param.customer_billing.id;
            }
          }
          /*   this.getAllQuotations(); */
        }
      },
    },
  },
  components: {
    Barcode,
    CustomerPersonDialog,
    ListingTable,
    CustomStatus,
    DateRangePicker,
  },
  methods: {
    getValidityDate(data) {
      if (data.added_at) {
        let date = moment(data.added_at, "YYYY-MM-DD").format("YYYY-MM-DD");
        let validity = moment(date, "YYYY-MM-DD")
          .add(data.validity_till, "days")
          .format("YYYY-MM-DD");
        return this.formatDate(validity);
      }
    },
    resetAll() {
      this.customerPerson = 0;
      this.customerBilling = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.customerPersonDialog = false;
    },
    selectCustomerPerson(param) {
      this.customerPerson = param;
      this.closeDialog();
      this.checkQuotation();
      if (!this.customerBilling || this.customerBilling <= 0) {
        this.resetAll();
      }
    },
    selectCustomerProperty(param) {
      this.customerBilling = param;
      this.closeDialog();
      this.checkQuotation();
    },
    checkQuotation() {
      const _this = this;
      if (
        _this.customer > 0 &&
        _this.customerPerson > 0 &&
        _this.customerBilling > 0
      ) {
        let queryObject = new Object({
          customer: _this.customer,
          contact_person: _this.customerPerson,
          billing: _this.customerBilling,
        });

        queryObject[_this.type] = _this.parent;

        _this.$router.push(
          _this.getDefaultRoute("quotation.create", {
            query: queryObject,
          })
        );
      }
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getAllQuotations();
    },
    getAllQuotations() {
      const _this = this;

      let filter = {
        per_page: 10,
        current_page: 1,
        status: _this.filter.status || "all",
        search: _this.filter.search || undefined,
        dates: _this.dates || undefined,
        type: _this.type,
        type_id: _this.parent,
      };
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      } else {
        filter.dates = undefined;
      }

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "quotation",
            data: filter,
          })
          .then(({ data }) => {
            _this.dataRows = data.rows;
            _this.statusList = data.status_list;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }, _this.timeoutLimit);
    },
  },
  mounted() {
    this.userDetails = JwtService.currentUser();
  },
};
</script>
